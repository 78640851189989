<template>
  <div class="py-4 py-lg-6 bg-primary">
    <div class="container">
      <div class="row">
        <div class="offset-lg-1 col-lg-10 col-md-12 col-12">
          <div class="d-lg-flex align-items-center justify-content-between">
            <div class="mb-4 mb-lg-0">
              <h1 class="text-white mb-1">Sửa đại lý</h1>
              <p class="mb-0 text-white lead">
                Điền thông tin rồi ấn Cập nhật để sửa đại lý
              </p>
            </div>
            <div>
              <router-link class="btn btn-white" :to="{name: 'AdminAgencyList'}">
                Danh sách đại lý
              </router-link>&nbsp;
              <button type="button" v-on:click="onSubmit"
                      class="btn btn-success ">Cập nhật
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="pb-12">
    <div class="container">
      <div id="agencyForm" class="bs-stepper">
        <div class="row">
          <div class="offset-lg-1 col-lg-10 col-md-12 col-12">
            <div class="bs-stepper-content mt-5">
              <form v-on:submit.prevent="">
                <div class="card mb-3 ">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-8">
                        <div class="mb-3">
                          <label for="name" class="form-label">Tên đại lý</label>
                          <input v-model="agency.name" id="name" class="form-control" type="text" required/>
                        </div>
                        <div class="mb-3">
                          <label class="form-label">Giới thiệu</label>
                          <Editor v-model:content="agency.content"/>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <Thumbnail v-on:setThumbnail="handleSetThumbnailEvent" :thumbnail="agency.thumbnail"/>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Media :mediaMode="mediaMode" v-on:setMedia="handleSetMediaEvent"/>
</template>

<script>
import Media from "@/components/Media";
import Thumbnail from "@/components/Thumbnail";
import Editor from "@/components/Editor";

import ApiService from "@/core/services/api.service";

export default {
  name: 'AddAgency',
  components: {
    Media,
    Thumbnail,
    Editor
  },
  data() {
    return {
      agency: {
        name: "",
        content: "",
        thumbnail_id: null,
        thumbnail: null
      },
      mediaMode: "thumbnail"
    }
  },
  methods: {
    handleSetThumbnailEvent() {
      this.mediaMode = "thumbnail";
    },
    handleSetMediaEvent(media) {
      this.agency.thumbnail = media;
      this.agency.thumbnail_id = parseInt(media.id);
    },
    onSubmit() {
      let query = `mutation($id: ID!, $name: String, $content: String, $thumbnail_id: Int) {
        updateAgency(
          input: {
            id: $id,
            name: $name,
            content: $content,
            thumbnail_id: $thumbnail_id
          }) {
            id
        }
      }`;

      ApiService.graphql(query, this.agency)
          .then(({data}) => {
            if (data.data && data.data.updateAgency) {
              this.$router.push({name: 'AdminAgencyList'})
            } else {
              alert("Sửa thất bại");
            }
          })
          .catch(({response}) => {
            console.log(response);
            alert("Sửa thất bại");
          });
    },
    loadAgency() {
      let query = `query {
        agency(code:"${this.$route.params.code}") {
          id
          code
          name
          content
          status
          thumbnail_id
          thumbnail {
            id
            link
            media_type
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data.agency) {
              this.agency = data.data.agency;
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    }
  },
  mounted() {
    this.loadAgency();
  }
}
</script>
